import * as React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import Hero from "@shared/hero";
import { HeroCarousel } from "@global";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import { Container, H3 } from "@util/standard";
import JotformEmbed from "react-jotform-embed";

const JotForm = () => {
  return (
    <Container padding="35px 0" flexDirection="column" alignItems="center">
      <H3>Sponsorship Application Form</H3>
      <JotformEmbed src={process.env.GATSBY_JOTFORM_SPONSORSHIP} />
    </Container>
  );
};

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const SponsorshipPage = ({ data }: Props) => {
  const sanitySponsorshipPage = data.allSanitySponsorshipPage.nodes[0];

  if (sanitySponsorshipPage == null) {
    return null;
  }

  const { hero, seo, additionalHeros, sponsorshipContent, relatedBlogs } =
    sanitySponsorshipPage;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {sanitySponsorshipPage &&
        hero &&
        additionalHeros &&
        additionalHeros.length > 0 ? (
          <HeroCarousel hero={hero} additionalHeros={additionalHeros} />
        ) : (
          hero && <Hero sanityHero={hero} />
        )}

        <ImageTextBlockWithCarousel
          containerWidth="80%"
          data={sponsorshipContent}
          insertNode={{
            position: 1,
            node: <JotForm />,
          }}
        />

        {relatedBlogs && relatedBlogs[0] && (
          <Container
            position="relative"
            width="calc(100% - 150px)"
            tabletWidth="calc(100% - 30px)"
          >
            <RecipePreviewCarousel
              blogs={relatedBlogs}
              showTabletNav
              width="100%"
            />
          </Container>
        )}
      </Layout>
    </>
  );
};

export default SponsorshipPage;

export const query = graphql`
  query sponsorQuery($iban: String) {
    allSanitySponsorshipPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        additionalHeros {
          ...sanityHero
        }
        sponsorshipContent {
          ... on SanityImageTextBlock {
            ...sanityImageTextBlock
          }
          ... on SanityGallery {
            ...sanityGallery
          }
          ... on SanityFullWidthTextBlock {
            ...sanityFullWidthTextBlock
          }
        }
        relatedBlogs {
          ...blogPreview
        }
      }
    }
  }
`;
